$primary: #01adb9;
$secundary: #e0218e;
$body: #231f20;
$gray: #616161;

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  color: $body;
  font-family: "Open Sans", sans-serif;
  font-size: 62.5%;

  display: grid;
  padding: 5rem 2rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-areas: "header""main""aside""footer";

  @media only screen and (min-width: 720px) {
    margin: 5rem auto;
    padding: 0 2rem;
    max-width: 720px;
  }
}

button {
  font-family: "Open Sans", sans-serif;
}

img {
  width: 100%;
  height: auto;
}

.profile {
  display: flex;
  height: 75px;
  align-items: center;
  margin: 2rem 0;

  .profile__image {
    margin-right: 2rem;
  }

  .profile__title {
    font-size: 1.5rem;
    line-height: 1.9rem;
    color: $gray;
  }
}

p,
a,
header,
main,
aside,
footer {
  font-size: 1.7rem;
  line-height: 2.6rem;
  font-family: "Open Sans", sans-serif;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

aside {
  grid-area: aside;
}

footer {
  grid-area: footer;
}

h1 {
  font-size: 4rem;
  font-family: "DM Sans", sans-serif;
}

h2 {
  font-size: 2.5rem;
  font-family: "DM Sans", sans-serif;
  margin: 2rem 0;
}

h3 {
  font-size: 2rem;
}

a {
  text-decoration: none;
  color: $primary;

  &:hover {
    color: $secundary;
  }

  &:visited {
    color: $primary;

    &:hover {
      color: $secundary;
    }
  }
}

nav {
  padding-bottom: 3rem;

  a {
    &:after {
      top: 4px;
      height: 22px;
      width: 4px;
      content: " |";
      color: #bcbfc5;
    }

    &:nth-last-child(1) {
      &:after {
        content: "";
      }
    }
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

figure {
  margin: 0;
  padding: 0;
}

figcaption {
  text-align: center;
}

.transcript {
  margin-top: 2rem;
}

.timetags {
  display: none;
  flex-direction: column;

  button {
    font-size: 1.6rem;
    text-align: left;
    padding: .5rem;
    background-color: #fff;
    border: 0;
  }

}

.show {
  display: flex;
}

.time {
  color: #231f20;

  span {
    color: $secundary;
  }

  &:hover {
    cursor: pointer;
  }
}

.controls {
  width: 100%;
  text-align: center;
}

.toogleTimetags {
  border: 1px solid $primary;
  background-color: #fff;
  padding: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.active {
  background-color: $primary;
  color: white;
}